import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import styles from "../styles/PromoSection.module.css";
import { Container, Row, Col } from "react-bootstrap";
import CarouselComponent from "./CarouselComponent";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import summer from "../images/summer2.png";

import { client } from "../client";

const PromoSection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [promo, setPromo] = useState([]);

  const cleanUpRawData = useCallback((rawData) => {
    const cleanData = rawData.map((item) => {
      const { sys, fields } = item;
      const { id } = sys;
      const itemCode = fields.promoCode;
      const oldPrice = fields.oldPrice;
      const newPrice = fields.newPrice;
      const itemImage = fields.promoImage.fields.file.url;

      const updatedData = {
        id,
        itemCode,
        oldPrice,
        newPrice,
        itemImage,
      };
      return updatedData;
    });

    setPromo(cleanData);
  }, []);

  const getAllEntries = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await client.getEntries({ content_type: "promohome" });
      const responseData = response.items;

      if (responseData) {
        cleanUpRawData(responseData);
      } else {
        setPromo([]);
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [cleanUpRawData]);

  useEffect(() => {
    getAllEntries();
  }, [getAllEntries]);

  return (
    <>
      {isLoading ? (
        <h1>Loading</h1>
      ) : (
        <div>
          <Container>
            <h1 className={styles.containerh1}>Explore Our Hot Deals</h1>
            <p className={styles.containerp}>- Share your magical moments</p>

            <CarouselComponent />

            {promo
              .sort((a, b) => a.itemCode - b.itemCode)
              .map((item) => {
                const { id, itemCode, oldPrice, newPrice, itemImage } = item;
                return (
                  <div key={id}>
                    <div id="promo" className={styles.container}>
                      <Container>
                        <Row className={styles.section_container}>
                          <Col md={6} xs={12} className={styles.section1}>
                            <LazyLoadImage
                              src={itemImage}
                              effect="blur"
                              placeholderSrc={itemImage}
                            />
                          </Col>
                          <Col md={6} xs={12} className={styles.section2}>
                            <div className={styles.text_container}>
                              <img src={summer} alt="logo" />

                              <h1>Summer "HOT" Sale!!!</h1>
                              <h2>
                                PROMO CODE:{" "}
                                <strong className={styles.strong}>
                                  HSS-{itemCode}
                                </strong>{" "}
                              </h2>
                              <h4>₱{oldPrice}</h4>
                              <h3>₱{newPrice}</h3>
                              <Link to="/promo_sets">
                                <button className={styles.button}>
                                  View More
                                </button>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </div>
                );
              })}
          </Container>
        </div>
      )}
    </>
  );
};

export default PromoSection;
