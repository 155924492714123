import React from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import Logo from '../images/logo1.png';

const Header = () => {
  return (
    <>
      <div>
        <Navbar
          collapseOnSelect
          expand='lg'
          bg='dark'
          variant='dark'
          fixed='top'
        >
          <Container>
            <LinkContainer to='/'>
              <div className='logo'>
                <Navbar.Brand>
                  <img src={Logo} alt='logo' />
                </Navbar.Brand>
              </div>
            </LinkContainer>
            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <Navbar.Collapse id='responsive-navbar-nav'>
              <Nav className='me-auto'>
                <LinkContainer to='/promo_sets'>
                  <Nav.Link>Promo Deals</Nav.Link>
                </LinkContainer>
              </Nav>
              <Nav>
                <LinkContainer to='/whisky'>
                  <Nav.Link>Whisky</Nav.Link>
                </LinkContainer>
                <LinkContainer to='/vodka'>
                  <Nav.Link>Vodka</Nav.Link>
                </LinkContainer>
                <LinkContainer to='/cognac'>
                  <Nav.Link>Cognac</Nav.Link>
                </LinkContainer>
                <LinkContainer to='/wine'>
                  <Nav.Link>Wine</Nav.Link>
                </LinkContainer>
                <LinkContainer to='/beer'>
                  <Nav.Link>Beer</Nav.Link>
                </LinkContainer>
                <LinkContainer to='/others'>
                  <Nav.Link>Others</Nav.Link>
                </LinkContainer>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default Header;
