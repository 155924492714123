import React from 'react';
import HeroSection from '../components/HeroSection';
// import FeaturedSection from '../components/FeaturedSection';
import PromoSection from '../components/PromoSection';
import CategoriesSection from '../components/CategoriesSection';

const LandingPage = () => {
  return (
    <>
      <div>
        <HeroSection />
        <PromoSection />

        <CategoriesSection />
      </div>
    </>
  );
};

export default LandingPage;
