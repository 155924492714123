import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Container, Col, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from '../styles/CategoriesSection.module.css';
import Whisky from '../images/whisky.jpg';

import Vodka from '../images/vodka.jpg';
import Beer from '../images/beer.jpg';
import Cognac from '../images/cognac.jpg';
import Wine from '../images/wine.jpg';
import Others from '../images/others.jpg';

const CategoriesSection = () => {
  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.containerh1}>More Choices</h1>
        <p className={styles.containerp}>-Quench your thirst</p>
        <Container>
          <Row>
            <Col sm={12} md={12} lg={4} xl={4}>
              <div className={styles.card_container}>
                <LazyLoadImage
                  src={Whisky}
                  effect='blur'
                  placeholderSrc={Whisky}
                />
                <h4>Whisky</h4>
              </div>
              <Link to='/whisky'>
                <Button className={styles.button}>View More</Button>
              </Link>
            </Col>
            <Col sm={12} md={12} lg={4} xl={4}>
              <div className={styles.card_container}>
                <LazyLoadImage
                  src={Vodka}
                  effect='blur'
                  placeholderSrc={Vodka}
                />
                <h4>Vodka</h4>
              </div>
              <Link to='/vodka'>
                <Button className={styles.button}>View More</Button>
              </Link>
            </Col>
            <Col sm={12} md={12} lg={4} xl={4}>
              <div className={styles.card_container}>
                <LazyLoadImage
                  src={Cognac}
                  effect='blur'
                  placeholderSrc={Cognac}
                />
                <h4>Cognac</h4>
              </div>
              <Link to='/cognac'>
                <Button className={styles.button}>View More</Button>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={12} lg={4} xl={4}>
              <div className={styles.card_container}>
                <LazyLoadImage src={Wine} effect='blur' placeholderSrc={Wine} />
                <h4>Wine</h4>
              </div>
              <Link to='/wine'>
                <Button className={styles.button}>View More</Button>
              </Link>
            </Col>
            <Col sm={12} md={12} lg={4} xl={4}>
              <div className={styles.card_container}>
                <LazyLoadImage src={Beer} effect='blur' placeholderSrc={Beer} />
                <h4>Beer</h4>
              </div>
              <Link to='/beer'>
                <Button className={styles.button}>View More</Button>
              </Link>
            </Col>
            <Col sm={12} md={12} lg={4} xl={4}>
              <div className={styles.card_container}>
                <LazyLoadImage
                  src={Others}
                  effect='blur'
                  placeholderSrc={Others}
                />
                <h4>Others</h4>
              </div>
              <Link to='/others'>
                <Button className={styles.button}>View More</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default CategoriesSection;
