import React from 'react';
import { Carousel, Container } from 'react-bootstrap';
import image1 from '../images/carousel/image1.jpg';
import image2 from '../images/carousel/image2.jpg';
import image3 from '../images/carousel/image3.jpg';

const CarouselComponent = () => {
  return (
    <>
      <Container>
        <div className='carousel_container'>
          <Carousel fade>
            <Carousel.Item>
              <img className='d-block w-100' src={image1} alt='First slide' />
              {/* <Carousel.Caption>
                <h3>First slide label</h3>
                <p>
                  Nulla vitae elit libero, a pharetra augue mollis interdum.
                </p>
              </Carousel.Caption> */}
            </Carousel.Item>

            <Carousel.Item>
              <img className='d-block w-100' src={image2} alt='Second slide' />
            </Carousel.Item>

            <Carousel.Item>
              <img className='d-block w-100' src={image3} alt='Third slide' />
            </Carousel.Item>
          </Carousel>
        </div>
      </Container>
    </>
  );
};

export default CarouselComponent;
