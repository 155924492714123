import React from 'react';
import styles from '../styles/HeroSection.module.css';
import { Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const HeroSection = () => {
  return (
    <>
      <section className={styles.box}>
        <Container>
          <div className={styles.text_content}>
            <div>
              <h3>PUB Jay</h3>
              <div className={styles.para_container}>
                <p>Whisky | Vodka | Cognac | Wine | Beer</p>
                <p>Gin | Tequila | Rum | Brandy</p>
              </div>

              <h4>We provide the most affordable liquors in Baguio City!</h4>
              <h4>Our products are 100% legit.</h4>

              <Link to='/promo_sets'>
                <Button className={styles.button}>View Now!</Button>
              </Link>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default HeroSection;
