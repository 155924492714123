import React from "react";
import styles from "../styles/ContactSection.module.css";
import { Container } from "react-bootstrap";

const ContactSection = () => {
  return (
    <>
      <div id="contact" className={styles.container}>
        <Container>
          <h1>Get in touch</h1>
          <div className={styles.content}>
            <div>
              {" "}
              <h3>
                <a href="https://www.facebook.com/alakattack">
                  <i className="fa-brands fa-facebook"></i>{" "}
                  <span>Facebook</span>
                </a>{" "}
              </h3>
              <h3>
                <a href="https://www.instagram.com">
                  <i className="fa-brands fa-instagram"></i>{" "}
                  <span>Instagram</span>
                </a>{" "}
              </h3>
            </div>

            <div>
              <h3>
                <a href="http://m.me/alakattack">
                  <i className="fa-brands fa-facebook-messenger"></i>{" "}
                  <span>Messenger</span>
                </a>
              </h3>

              <h3>
                <a href="tel:+639171188114">
                  <i className="fa-solid fa-phone"></i> <span>Call Us Now</span>
                </a>
              </h3>
            </div>
            <div>
              <h3>
                <a href="mailto: bgjaytan@gmail.com">
                  <i className="fa-solid fa-envelope"></i>{" "}
                  <span>Send Email</span>
                </a>
              </h3>
            </div>
          </div>

          <div className={styles.policy}>
            <p>
              Please do not share with anyone under the legal purchase age for
              alcohol. Drink Responsibly.
            </p>
          </div>
        </Container>
      </div>
    </>
  );
};

export default ContactSection;
