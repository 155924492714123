import React, { useState } from 'react';
import styles from '../styles/PopupComponent.module.css';

const PopUpComponent = () => {
  const [isShown, setIsShown] = useState(true);
  const handleClick = (e) => {
    setIsShown((current) => !current);
  };

  return (
    <>
      <div
        className={styles.popup_wrapper}
        style={{ display: isShown ? 'block' : 'none' }}
      >
        <div className={styles.popup}>
          <div className={styles.popup_content}>
            <h1>
              This Website is for <strong>18 years old</strong> and above only!
            </h1>
            <p>Verify that you are 18 years of age.</p>
            <div className={styles.buttons}>
              <button className={styles.popup_yes} onClick={handleClick}>
                18 & Above
              </button>
              <button className={styles.popup_no}>Below 18 </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PopUpComponent;
