import React, { useState, useEffect, useCallback } from "react";
import styles from "../styles/TableComponent.module.css";
import { Container, Table } from "react-bootstrap";
import { client } from "../client";

const CognacScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [product, setProduct] = useState([]);

  const cleanUpRawData = useCallback((rawData) => {
    const cleanData = rawData.map((item) => {
      const { sys, fields } = item;
      const { id } = sys;
      const itemId = fields.cognacId;
      const itemBrand = fields.brand;
      const itemDescription = fields.description;
      const itemBottleSize = fields.bottleSize;
      const itemPrice = fields.price;

      const updatedData = {
        id,
        itemId,
        itemBrand,
        itemDescription,
        itemBottleSize,
        itemPrice,
      };
      return updatedData;
    });

    setProduct(cleanData);
  }, []);

  const getAllEntries = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await client.getEntries({ content_type: "cognac" });
      const responseData = response.items;

      if (responseData) {
        cleanUpRawData(responseData);
      } else {
        setProduct([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [cleanUpRawData]);

  useEffect(() => {
    getAllEntries();
  }, [getAllEntries]);

  return (
    <>
      {isLoading ? (
        <h1>Loading</h1>
      ) : (
        <div>
          <Container>
            <h1 className={styles.table_h1}>Cognac Selection</h1>
            <div className={styles.table_container}>
              <h2 className={styles.table_h2}>Pricelist</h2>
              <Table striped bordered hover variant="dark">
                <tbody>
                  {product
                    .sort((a, b) => a.itemId - b.itemId)
                    .map((item) => {
                      const {
                        id,
                        itemDescription,
                        itemBottleSize,
                        itemPrice,
                        itemBrand,
                      } = item;

                      return (
                        <tr key={id}>
                          <td className={styles.container_td}>{itemBrand}</td>
                          <td>{itemDescription}</td>
                          <td>{itemBottleSize}</td>
                          <td>₱{itemPrice}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default CognacScreen;
