import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';
import Footer from './components/Footer';
import ContactSection from './components/ContactSection';

import LandingPage from './screen/LandingPage';
import PromoScreen from './screen/PromoScreen';
import WhiskyScreen from './screen/WhiskyScreen';
import VodkaScreen from './screen/VodkaScreen';
import CognacScreen from './screen/CognacScreen';
import WineScreen from './screen/WineScreen';
import BeerScreen from './screen/BeerScreen';
import OtherScreen from './screen/OtherScreen';

import PopUpComponent from './components/PopUpComponent';

const App = () => {
  return (
    <Router>
      <>
        <div>
          <PopUpComponent />
          <Header />
          <main>
            <ScrollToTop />

            <Routes>
              <Route path='/' element={<LandingPage />} />
              <Route path='/whisky' element={<WhiskyScreen />} />
              <Route path='/vodka' element={<VodkaScreen />} />
              <Route path='/cognac' element={<CognacScreen />} />
              <Route path='/wine' element={<WineScreen />} />
              <Route path='/beer' element={<BeerScreen />} />
              <Route path='/others' element={<OtherScreen />} />
              <Route path='/promo_sets' element={<PromoScreen />} />
            </Routes>
          </main>
          <ContactSection />
          <Footer />
        </div>
      </>
    </Router>
  );
};

export default App;
