import { useState, useEffect, useCallback } from "react";
import styles from "../styles/CardComponent.module.css";
import { Container, Col, Row, Card, ListGroup } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import { client } from "../client";

const PromoScreen = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [promo, setPromo] = useState([]);

  const cleanUpRawData = useCallback((rawData) => {
    const cleanData = rawData.map((item) => {
      const { sys, fields } = item;
      const { id } = sys;
      const itemId = fields.promoId;
      const itemTitle = fields.title;
      const itemBottleSize = fields.bottleSize;
      const itemPrice = fields.price;
      const itemImage = fields.promoImage.fields.file.url;

      const updatedData = {
        id,
        itemId,
        itemTitle,
        itemBottleSize,
        itemPrice,
        itemImage,
      };
      return updatedData;
    });

    setPromo(cleanData);
  }, []);

  const getAllEntries = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await client.getEntries({ content_type: "promo" });
      const responseData = response.items;

      if (responseData) {
        cleanUpRawData(responseData);
      } else {
        setPromo([]);
      }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [cleanUpRawData]);

  useEffect(() => {
    getAllEntries();
  }, [getAllEntries]);

  return (
    <>
      {isLoading ? (
        <h1>Loading</h1>
      ) : (
        <div className={styles.container}>
          <Container>
            <h1 className={styles.screen_h1}>Promo & Deals</h1>
            <Row>
              {promo
                .sort((a, b) => a.itemId - b.itemId)
                .map((item) => {
                  const {
                    id,
                    itemId,
                    itemTitle,
                    itemBottleSize,
                    itemPrice,
                    itemImage,
                  } = item;

                  return (
                    <Col key={id} sm={12} md={6} lg={4} xl={4}>
                      <div className={styles.container}>
                        <Card className={styles.card_container}>
                          <LazyLoadImage
                            key={id}
                            src={itemImage}
                            effect="blur"
                            placeholderSrc={itemImage}
                          />

                          <Card.Body className={styles.card_body}>
                            <ListGroup className={styles.list_group}>
                              <ListGroup.Item
                                className={styles.list_group_item}
                              >
                                {itemTitle}
                              </ListGroup.Item>
                              <ListGroup.Item
                                className={styles.list_group_item}
                              >
                                Bottle Sizes: {itemBottleSize}
                              </ListGroup.Item>
                              <ListGroup.Item
                                className={styles.list_group_item}
                              >
                                Promo Code: {""}
                                <strong className={styles.strong}>
                                  HSS-{itemId}
                                </strong>
                              </ListGroup.Item>

                              <ListGroup.Item
                                className={styles.list_group_item}
                              >
                                <strong className={styles.strong}>
                                  {" "}
                                  ₱{itemPrice}
                                </strong>
                              </ListGroup.Item>
                            </ListGroup>
                          </Card.Body>
                          <a
                            className={styles.a_link}
                            href="http://m.me/alakattack"
                          >
                            Order Now
                          </a>
                        </Card>
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </Container>
        </div>
      )}
    </>
  );
};

export default PromoScreen;
